@mixin mediaSm {
    .home{
        width: 100%;
        .info{
            .note{
                .text{
                    width: auto;
                }
            }
        }
    }
    // .not-resp{
    //     padding: 0 15px 0 15px;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     min-height: 6rem;
    //     align-items: center;
    //     .menu-burger{
    //         display: inherit;
    //         height: min-content;
    //         .menu-btn{
    //             background-color: transparent;
    //             height: min-content;
    //             .fa{
    //                 font-size: 30px;
    //             }
    //         }
    //     }
    //     .hide-menu{
    //         visibility: hidden;
    //         width: 0;
    //         background-color: blanchedalmond;
    //         height: 0;
    //     }
    // }
    

    // .responsive{
    //     padding: 0 15px 0 15px;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     min-height: 6rem;
    //     align-items: baseline;
    //     .hide-menu{
    //         visibility: visible;
    //         width: 60%;
    //         height: min-content;
    //         overflow: hidden;
    //         .wraper{
    //             .links{
    //                 display: flex;
    //                 flex-direction: column;
    //                 width: min-content;
    //                 margin: 0;
    //                 li{
    //                     font-size: 15px;
    //                 }
    //             }
    //         }
    //         .icon-wrapper{
    //             justify-content: start;
    //             a{
    //                 i{
    //                     font-size: 20px;
    //                 }
    //             }
    //         }
    //     }
    //     .menu-burger{
    //         display: inherit;
    //         height: min-content;
    //         .menu-btn{
    //             background-color: transparent;
    //             height: min-content;
    //             .fa{
    //                 font-size: 30px;
    //             }
    //         }
    //     }
    // }
    // .header{
        
    //     // .wraper {
    //     //     width: 100%;
    //     //     .links{
    //     //         display: flex;
    //     //         width: 100%;
    //     //         min-height: 5rem;
    //     //         li{
    //     //             display: flex;
    //     //             font-size: 20px;
    //     //             justify-content: space-between;
    //     //             flex-grow: 1;
    //     //             a{
    //     //                 color: #eae6e6;
    //     //                 text-decoration: none;
    //     //                 &:hover{
    //     //                 color: rgb(140, 154, 236);
    //     //                 border-bottom: 2px solid #eae6e6;
    //     //             }
    //     //             }
    //     //         }
    //     //     }
    //     // }
    // }

    .projects{
        font-size: 20px;
        width: 100%;
        margin-top: 5rem;
        margin-bottom: 8rem;
        padding-top: 3rem;
        .work-wrapper{
            margin: auto;
            padding: 10px;

            .card-body{
                margin-left: 0;
                padding: 2px;
                font-size: smaller;
                font-weight: bold;
                width: 90%;
                margin: 6px auto;
                border-radius: 25px;

                img{
                    border-radius: 25px;
                    height: 300px;

                }
            }
            .sm-cards{
                width: 240px;
                margin: 4px auto;
                border-radius: 25px;
                font-size: 13px;
                font-weight: 400;
                padding: 7px;

                li{
                    display: inline-flex;
                }
                p{
                    display: inline-block;
                }
            }

        }
        .skills{
            .list{
                display: flex;
                justify-content: flex-start;
                .name{
                    width: 40%;
                    height: 13rem;
                    justify-content: flex-start;
                    font-size: 16px;
                    margin: 5px;
                }
            }
        }
    }

    .icon-wrapper{
        display: flex;
        justify-content: center;
        margin-top: 1px;
        a{
            margin: 10px
        }
    }
}
  
@mixin mediaMd {
    .home{
        .info{
            .note{
                .text{
                    width: 34rem;
                }
            }
        }
    }


    .header{
        .wraper {
            width: 100%;
            .links{
                display: flex;
                width: 100%;
                li{
                    display: flex;
                    font-size: 20px;
                    justify-content: space-between;
                    a{
                    color: #eae6e6;
                    text-decoration: none;
                    &:hover{
                    color: rgb(140, 154, 236);
                    border-bottom: 2px solid #eae6e6;
                    }
                    }
                }
            }
        }
    }

    .projects{
        font-size: 27px;
        margin-top: 7rem;
        margin-bottom: 8rem;
        padding-top: 5rem;
        .works{
            margin: auto;
            padding: 10px;

            .rows{
                margin-right: 0;
                margin-left: 0;

                .cards{
                    font-size: 18px;
                    font-weight: 800;
                    margin-left: 1px;
                    width: 240px;
                    padding: 4px;
                    margin: 5px auto;
                    border-radius: 25px;

                    img{
                        border-radius: 25px;
                        height: 280px;
                    }
                }

                .sm-cards{
                    margin: 4px auto;
                    width: 240px;
                    border-radius: 25px;
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }
        .skills{
            .list{
                display: flex;
                justify-content: flex-start;
                .name{
                    width: 46%;
                    height: 12rem;
                    justify-content: flex-start;
                    margin: 2px;
                    font-size: x-large;
                }
            }
        }
    }

    .footer{

        .wraper {
            margin-left: 2rem;
            margin-right: 2rem;
            width: 100%;
        }

    }

  }
  
  @mixin mediaLg {
    .home{
        .info{
            .note{
                .text{
                    width: 40rem;
                }
            }
        }
    }
    .header{

        .wraper {
            width: 100%;
        }

        .links{
            li{
                font-size: 25px;
            }
        }
    }

    .projects{
        font-size: 20px;
        width: 100%;
        margin-top: 10rem;
        margin-bottom: 8rem;

        .work-wrapper{
            margin: auto;
            width: 80%;
            padding: 10px;

 
            .card-body{
                font-size: 18px;
                font-weight: 800;
                margin-left: 1px;
                width: 48%;
                padding: 4px;
                margin: 2px 4px 10px;
                border-radius: 25px;
                display: flex;
                flex-flow: wrap;

                img{
                    border-radius: 25px;
                    height: 310px;
                }
            }

            .sm-cards{
                margin: 8px auto;
                width: 48%;
                border-radius: 25px;
                font-size: 17px;
                font-weight: 400;
            }
            
        }
    }


    .footer{

        .wraper {
            margin-left: 2rem;
            margin-right: 2rem;
            width: 100%;
        }

    }
  }
  
  @mixin mediaXL {

    .home{
        .info{
            .note{
                .text{
                    width: 40rem;
                }
            }
        }
    }
    .header{
        .wraper {
            width: 100%;
        }


        .links{
            li{
                font-size: 25px;
            }
        }
    }

    .projects{
        font-size: 35px;
        width: 100%;
        padding-top: 2rem;
        .work-wrapper{
            margin: 0 auto;
            width: 90%;
            padding: 10px;

            .card-body{
                // font-size: 19px;
                font-weight: 600;
                // margin: 0 auto;
                width: 315px;

                img{
                    border-radius: 25px;
                    height: 310px;
                }
            }
            
        }
    }

    .footer{
        .wraper {
            margin-left: 2rem;
            margin-right: 2rem;
            width: 100%;
        }

    }
  }